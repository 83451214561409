import React from 'react'
import './simple-loading.css'

const SimpleLoading: React.FC<{ text: string }> = ({ text }) => {
    const spinnerRef = React.useRef(null)

    React.useEffect(() => {
        const spinner: any = spinnerRef.current
        if (!spinner) return;
        const animation = spinner.animate(
            [
                { transform: 'rotate(0)' },
                { transform: 'rotate(360deg)' },
            ],
            {
                duration: 1200,
                iterations: Infinity
            }
        );
        animation.play()
        return () => {
            animation.cancel()
        }

    }, [spinnerRef])

    return (
        <div className="simple-loading" >
            <div ref={spinnerRef} className="spinner" >{text}</div>
        </div>

    );
}

export default SimpleLoading