import React from 'react'

const useCacheImage = (imageURLs: string[]) => {
    const [ready, setReady] = React.useState(false)
    React.useEffect(() => {
        const tasks = imageURLs.map(url => new Promise<void>((resolve) => {
            let img: HTMLImageElement | null = new Image();
            img.onload = () => {
                img = null
                resolve()
            }
            img.src = url
        }))
        let cancel = false
        const onCancel = () => { cancel = true };
        Promise.all(tasks).then(() => {
            if (!cancel) setReady(true);
        })
        return onCancel
    }, [imageURLs])
    return ready
}

export default useCacheImage