import React, { Suspense } from 'react';
import './App.css'
import {
  Switch,
  Route,
} from "react-router-dom";
import useCacheImage from '../hooks/useCacheImage';
import SimpleLoading from './simple-loading/simple-loading';
const CocoComponentP = import('./coco/coco')
const CocoFrameP = import('./coco-frame/coco-frame')

const CocoComponentAsync = React.lazy(() => CocoComponentP);
const CocoFrameAsync = React.lazy(() => CocoFrameP);
const MainPageAsync = React.lazy(() => import('./main-page/main-page'));

const images = [
  "/coco/box.webp",
  "/coco/givemeyoursoul.webp",
  "/coco/letmesleeppls.webp",
  "/coco/lick.webp",
  "/coco/stare.webp",
  "/coco/okay.webp",
  "/coco/toilet.webp",
  "/coco/boxsleep.webp",
  "/coco/hellothere.webp",
  "/coco/cocowash.webp",
  "/coco/telecoco.webp",
  "/coco/destroy.webp",
]

const FullPageLoading: React.FC<{ text: string }> = props => {
  const { text } = props || ""
  return (
    <div style={
      { backgroundColor: 'black', display: 'flex', width: '100vw', height: '100vh', alignItems: 'center', justifyContent: 'center' }} >
      <SimpleLoading text={text} ></SimpleLoading>
    </div>
  )
}

const CocoContainer: React.FC = () => {
  const imagesReady = useCacheImage([...images, "/coco/supreme.webp"])
  const [delayDone, setDelayDone] = React.useState(false)
  const [componentsLoaded, setComponentsLoaded] = React.useState(false)
  const [clicked, setClicked] = React.useState(false)
  const [{ onClick }, setOnClick] = React.useState<any>({ onClick: () => { } })
  const audioRef = React.useRef(null)

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      setDelayDone(true)
    }, 1000)
    return () => clearTimeout(timeout)
  }, [])

  React.useEffect(() => {
    if (!imagesReady && !delayDone) return;
    const audio: any = audioRef.current
    if (!audio) return;

    let clickCount = 0
    const onClick = () => {
      clickCount++
      if (clickCount === 5) {
        audio.pause()
        audio.currentTime = 0
        audio.play()
        setTimeout(() => {
          setClicked(true)
        }, 7300)
      }
    }
    setOnClick({ onClick })
  }, [imagesReady, delayDone, audioRef])

  React.useEffect(() => {
    Promise.all([CocoFrameP, CocoComponentP]).then(() => {
      setComponentsLoaded(true)
    })
  }, [])

  const loading = <FullPageLoading text="🥥" />
  const layout = (
    <Suspense fallback={loading} >
      <div onClick={onClick} onTouchEnd={e => { e.preventDefault(); onClick() }} >
        <audio onPause={() => setClicked(false)} ref={audioRef} src="/audio/pink-baby.mp3" />
        {
          clicked ? <CocoComponentAsync images={images} /> : <CocoFrameAsync></CocoFrameAsync>
        }
      </div>
    </Suspense>
  );
  return imagesReady && delayDone && componentsLoaded ? layout : loading
}

const App: React.FC = () => {
  return (
    <Switch>
      <Route exact path="/">
        <Suspense fallback={<FullPageLoading text="💻" />}>
          <MainPageAsync></MainPageAsync>
        </Suspense>
      </Route>
      <Route path="/coco">
        <CocoContainer></CocoContainer>
      </Route>
    </Switch>
  )
};

export default App;